import React, { useRef, useState } from "react";
import { createPopper } from "@popperjs/core";
import { logout } from "../../store/actions/auth";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { useOutsideCallback } from "hooks/useOutsideCallback";
import Link from "next/link";

const UserDropdown = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const btnDropdownRef = useRef();
  const popoverDropdownRef = useRef();
  const router = useRouter();
  const dispatch = useDispatch();
  const openDropdownPopover = () => {
    setDropdownPopoverShow(true);
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  useOutsideCallback(popoverDropdownRef, closeDropdownPopover, [
    btnDropdownRef,
  ]);

  const logoutHandler = (e: any) => {
    e.preventDefault();

    if (!!dispatch) {
      dispatch(logout() as any).then(() => {
        router.push("/auth/login");
      });
    }
  };

  return (
    <>
      <a
        className="text-white block hover:no-underline no-underline"
        href="#"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <i className="icon icon-account-circle text-3xl" />
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "user-dropdown bg-black text-base z-50 float-left py-2 list-none text-left shadow-lg min-w-48 shadow-tooltip border-2 border-white mt-4 rounded"
        }
      >
        <Link href="/profile">
          <a
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white"
            }
          >
            Profile
          </a>
        </Link>
        <a
          href="#"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white"
          }
          onClick={logoutHandler}
        >
          Log Out
        </a>
        <Link href={"https://www.popflick.com/contact-us"} passHref>
          <a
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white"
            }
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            Contact Us
          </a>
        </Link>
        <Link href={"https://www.popflick.com/contributor-terms"} passHref>
          <a
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white"
            }
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            Terms of Service
          </a>
        </Link>
      </div>
    </>
  );
};

export default UserDropdown;
