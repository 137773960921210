import React, { useMemo, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import UserDropdown from "../../components/Dropdowns/UserDropdown";
import { config } from "../../config";
import Image from "next/image";

import styles from "@styles/components/Sidebar.module.scss";
import classNames from "classnames";
import { useUser } from "hooks/useUser";

export default function Sidebar() {
  const user = useUser();

  const [collapseShow, setCollapseShow] = useState("hidden");
  const router = useRouter();
  const isAdmin = useMemo(
    () => user?.groups.some((group: string) => group === "Admins"),
    [user]
  );

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden md:!pt-16  flex flex-wrap items-center justify-between relative md:w-64 z-10 pt-4 pb-4 pr-6  pl-6 md:pl-14 bg-[#1A1A1A]">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow(" m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            href="/"
            className="md:block text-left md:pb-2 text-white mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
          >
            <a>
              <Image
                src={`${config.staticFileBaseUrl}/svg/popflicklogonav.svg`}
                height={36}
                width={133}
                alt={""}
              />
            </a>
          </Link>
          <span className="span-version">Beta v0.5</span>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={`md:flex md:flex-col md:items-stretch md:opacity-100 md:relative mt-24 absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ${collapseShow}`}
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    href="/"
                    className="md:block text-left md:pb-2 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                  >
                    <a>
                      <Image
                        src={`${config.staticFileBaseUrl}/svg/popflicklogonav.svg`}
                        height={36}
                        width={133}
                        alt={""}
                      />
                    </a>
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fa-solid fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500   rounded text-base leading-snug outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link href="/">
                  <a
                    className={classNames(styles.menuItem, {
                      [styles.menuItemActive]:
                        router.pathname === "/" ||
                        router.pathname.indexOf("/views") !== -1,
                    })}
                  >
                    <i className="icon icon-stats"></i>
                    {user?.hide_statistics ? "Uploaded Content" : "Statistics"}
                  </a>
                </Link>
              </li>

              {isAdmin ? (
                <li className="items-center">
                  <Link href="/users">
                    <a
                      className={classNames(styles.menuItem, {
                        [styles.menuItemActive]:
                          router.pathname.indexOf("/users") !== -1,
                      })}
                    >
                      <i className="fa-solid fa-users"></i>
                      Users Admin
                    </a>
                  </Link>
                </li>
              ) : (
                <></>
              )}
              {(isAdmin || user?.allow_upload) && (
                <>
                  <li className="items-center">
                    <Link
                      href={
                        isAdmin ? "/film-manager" : "/film-manager/new-film"
                      }
                    >
                      <a
                        className={classNames(styles.menuItem, {
                          [styles.menuItemActive]:
                            router.pathname.indexOf("/film-manager") !== -1 &&
                            router.pathname.indexOf("/views") < 0 &&
                            router.pathname.indexOf("/new-series") < 0,
                        })}
                      >
                        <i className="icon icon-circle-arrow-up"></i>
                        {isAdmin ? "Film Manager" : "Upload Film"}
                      </a>
                    </Link>
                  </li>
                  {!isAdmin && (
                    <li className="items-center">
                      <Link href={"/film-manager/new-series"}>
                        <a
                          className={classNames(styles.menuItem, {
                            [styles.menuItemActive]:
                              router.pathname.indexOf(
                                "/film-manager/new-series"
                              ) !== -1 && router.pathname.indexOf("/views") < 0,
                          })}
                        >
                          <i className="icon icon-circle-arrow-up"></i>
                          {"Upload TV Show"}&nbsp;
                          <Image
                            src={`${config.staticFileBaseUrl}/images/new.png`}
                            height={16}
                            width={32}
                            alt={"(new)"}
                          />
                        </a>
                      </Link>
                    </li>
                  )}
                </>
              )}
              {isAdmin ? (
                <li className="items-center">
                  <Link href="/ads">
                    <a
                      className={classNames(styles.menuItem, {
                        [styles.menuItemActive]:
                          router.pathname.indexOf("/ads") !== -1,
                      })}
                    >
                      <i className="fa-solid fa-rectangle-ad"></i>
                      Managment
                    </a>
                  </Link>
                </li>
              ) : (
                <></>
              )}
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full border-popflick-yellow" />

            <ul>
              {isAdmin && (
                <li className="items-center">
                  <Link href="/s3-files">
                    <a className={styles.menuItem}>S3 Files</a>
                  </Link>
                </li>
              )}
              <li className="items-center">
                <Link href={"https://www.popflick.com/contact-us"} passHref>
                  <a
                    className={styles.menuItem}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                  >
                    Contact Us
                  </a>
                </Link>
              </li>
              <li className="items-center">
                <Link
                  href={"https://www.popflick.com/contributor-terms"}
                  passHref
                >
                  <a
                    className={styles.menuItem}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                  >
                    Terms of Service
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
